import client from "../toolApi"
import { DataLayer } from "../../helpers/GA"
export const toPNG = (data, setDownload) =>
  client
    .post("/uploadNew", data.formData)
    .then(res => {
      window.fileName = res.data.message[0]
      DataLayer("Online tool", "Upload", "Pdf to bmp")
      client
        .get(
          `/convertTool?pages=all&extention=${data.extension}&fileName=${window.fileName}&password=${data.password}`
        )
        .then(res => {
          DataLayer("Online tool", "Convert", "Pdf to bmp")
          client
            .get(`/downloadConvert?fileName=${window.fileName}`, {
              responseType: "blob",
            })
            .then(res => {
              console.log("Downloaded: ", res)
              const link = document.createElement("a")
              setDownload(link)
              link.href = URL.createObjectURL(new Blob([res.data]))
              link.download = `pdfpro_${data.extension}.zip`
              document.body.appendChild(link)
              link.click()
              DataLayer("Online tool", "Download", "Pdf to bmp")
            })
            .catch(() => {
              DataLayer("Online tool", "Error download", "Pdf to bmp")
            })
        })
        .catch(() => {
          DataLayer("Online tool", "Error convert", "Pdf to bmp")
        })
    })
    .catch(() => {
      DataLayer("Online tool", "Error upload", "Pdf to bmp")
    })
