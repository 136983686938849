import React, { useEffect, useState } from "react"
import {
  Container,
  TitleSub,
  TitleMain,
  FlexBox,
  DescriptionBox2,
  DescriptionBox3,
  TitleHolder,
  DescriptionBoxHolder,
  ContainerDesk,
  NumberHolder,
  ImageGroup1,
  DescriptionBox,
  Title,
  Description,
  ExampleImg,
} from "../../../ToolsStyles/HowItWorksStyeled"

import Step1 from "../../Assets/HowToImage_Step_1_pdftobmp.png"
import Step2 from "../../Assets/HowToImage_Step_2_pdftobmp.png"
import Step3 from "../../Assets/HowToImage_Step_3_pdftobmp.png"
import Group1 from "../../../merge/MergeAssets/Group1.svg"
import Group2 from "../../../merge/MergeAssets/Group2.svg"
import Group3 from "../../../merge/MergeAssets/Group3.svg"
import ActiveGroupOne from "../../../merge/MergeAssets/GroupActiveOne.svg"
import ActiveGroupTwo from "../../../merge/MergeAssets/GroupActiveTwo.svg"
import GroupActiveThree from "../../../merge/MergeAssets/GroupActiveThree.svg"
import HowItWorksMobile from "./HowItWorksMobile"

const HowItWorks = () => {
  const [IsActive, setIsActive] = useState(1)

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     if (!window.clicked) {
  //       setIsActive(prevActive => {
  //         const newActive = prevActive === 3 ? 1 : prevActive + 1
  //         return newActive
  //       })
  //     } else {
  //       window.clicked = false
  //     }
  //   }, 5000)

  //   return () => {
  //     clearInterval(intervalId)
  //   }
  // }, [])

  const ActiveTab = id => {
    setIsActive(id)

    window.clicked = true
  }

  return (
    <div>
      <ContainerDesk>
        <FlexBox>
          <DescriptionBoxHolder>
            <DescriptionBox
              onClick={() => ActiveTab(1)}
              ActiveState={1 == IsActive}
            >
              {IsActive === 1 ? (
                <ImageGroup1 src={ActiveGroupOne} />
              ) : (
                <ImageGroup1 src={Group1} />
              )}

              <Title ActiveState={IsActive === 1}>Upload PDF</Title>
              <Description>
                Upload the PDF you want to convert to BMP using the upload
                button, or drag and drop the file into window.
              </Description>
            </DescriptionBox>

            <DescriptionBox
              onClick={() => ActiveTab(2)}
              ActiveState={2 == IsActive}
            >
              {IsActive === 2 ? (
                <ImageGroup1 src={ActiveGroupTwo} />
              ) : (
                <ImageGroup1 src={Group2} />
              )}
              <Title ActiveState={IsActive === 2}>Press Convert to BMP</Title>
              <Description>
                Follow the on-screen prompts to convert your PDF.
              </Description>
            </DescriptionBox>

            <DescriptionBox
              onClick={() => ActiveTab(3)}
              ActiveState={3 == IsActive}
            >
              {IsActive === 3 ? (
                <ImageGroup1 src={GroupActiveThree} />
              ) : (
                <ImageGroup1 src={Group3} />
              )}
              <Title ActiveState={IsActive === 3}>Download!</Title>
              <Description>Download your new BMP file!</Description>
            </DescriptionBox>
          </DescriptionBoxHolder>

          {IsActive === 1 ? (
            <ExampleImg
              onClick={() => {
                window.setTimeout(() => window.scrollTo(window.scrollX, 0), 1)
              }}
              src={Step1}
              alt="example of how to upload file"
            />
          ) : null}
          {IsActive === 2 ? (
            <ExampleImg
              onClick={() =>
                window.setTimeout(() => window.scrollTo(window.scrollX, 0), 1)
              }
              src={Step2}
              alt="example of how to merge file"
            />
          ) : null}
          {IsActive === 3 ? (
            <ExampleImg
              onClick={() =>
                window.setTimeout(() => window.scrollTo(window.scrollX, 0), 1)
              }
              src={Step3}
              alt="example of how to download file"
            />
          ) : null}
        </FlexBox>
      </ContainerDesk>
      <HowItWorksMobile />
    </div>
  )
}

export default HowItWorks
